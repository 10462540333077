import React from 'react';
import './App.css';

const Smallsh = () => {
  return (
    <div className="smallsh-body">
      <h2>smallsh - a custom linux shell in C</h2>
      <div className="iframe-wrapper">
        <div className="iframe-container">
          <iframe src={process.env.REACT_APP_SMALLSH_URL} title="smallsh" />
        </div>
      </div>
      <a
        className="App-link"
        href="https://gitlab.lyond-software.net/portfolio/smallsh"
        target="_blank"
        rel="noopener noreferrer"
      >
        Source Code
      </a>
    </div>
  );
};

export default Smallsh;