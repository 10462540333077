import React from 'react';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
const ProjectExplorer = () => {
    return (
        <>
            <Breadcrumb className="project-explorer-breadcrumb">
                    <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                    <Breadcrumb.Item active>Projects</Breadcrumb.Item>
            </Breadcrumb>
            <div className="project-explorer-body">
                <Tabs
                    defaultActiveKey="all-projects"
                    className="project-explorer-tabs">
                    <Tab eventKey="all-projects" title="All Projects"></Tab>
                    <Tab eventKey="ai-projects" title="AI/ML"></Tab>
                    <Tab eventKey="devops-projects" title="DevOps"></Tab>
                </Tabs>
            </div>
        </>
    );
};

export default ProjectExplorer;