import React from 'react';
import './App.css';

const ProjectAICrypto = () => {
    return (
        <div className="aicrypto-body">
            <h2>AI Neural Network Crypto Trader</h2>
            <div className="iframe-wrapper">
                <div className="iframe-container">
                    <iframe src="https://www.youtube.com/embed/11RbgNXIHwE?si=vbc-r01sZHSmf7dZ"
                            title="AI Crypto Trader"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            referrerPolicy="strict-origin-when-cross-origin" allowFullScreen>
                    </iframe>
                </div>
            </div>
            <a
                className="App-link"
                href="https://gitlab.lyond-software.net/lyond/CS467_AI_Bitcoin_Trader/-/tree/main/Final%20Archive/LSTM_Trader"
                target="_blank"
                rel="noopener noreferrer"
            >
            Source Code
            </a>
        </div>
    );
};

export default ProjectAICrypto;