import React from 'react';

const ProjectFlaskApp = () => {
  return (
    <div style={{ width: '100%', height: '100vh' }}>
      <iframe
        src="/flask_app/"
        style={{
          width: '100%',
          height: '100%',
          border: 'none',
          backgroundColor: 'white'
          }}
        title="Flask CRUD App"
      />
    </div>
  );
}

export default ProjectFlaskApp;
