import React, { useState, useEffect, useRef } from 'react';
import './App.css';
import lion from './colorful-1197323_640.png';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import {BrowserRouter as Router, Route, Routes, Link} from 'react-router-dom';
import Home from './Home';
import About from './About';
import Contact from './Contact';
import ProjectExplorer from './ProjectExplorer';
import ProjectSmallsh from "./ProjectSmallsh";
import ProjectAICrypto from "./ProjectAICrypto";
import ProjectFlaskApp from "./ProjectFlaskApp";


function App() {
  const [navbarHeight, setNavbarHeight] = useState(0);
  const navbarRef = useRef(null);

  useEffect(() => {
    const updateNavbarHeight = () => {
      if (navbarRef.current) {
        console.log("navbarRef.current: ", navbarRef.current);
        setNavbarHeight(navbarRef.current.offsetHeight);
        console.log("Navbar height: ", navbarRef.current.offsetHeight);
      }
    };
    updateNavbarHeight();
    window.addEventListener('resize', updateNavbarHeight);
    return () => window.removeEventListener('resize', updateNavbarHeight);
  }, []);

  const appBodyStyle = {
    minHeight: `calc(100vh - ${navbarHeight}px)`,
    overflowY: 'auto',
  };


  return (
    <Router>
      <div className="App">
        <header className="App-header" ref={navbarRef}>
          <Navbar expand="lg" bg="dark" data-bs-theme="dark">
            <Container>
              <Navbar.Brand href="/">
                <img src={lion} width="40" height="29" style={{marginRight:'20px'}}/>
                lyond-software
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="ms-auto">
                  <Nav.Link href="/">Home</Nav.Link>
                  <NavDropdown title="Projects" id="basic-nav-dropdown">
                    <NavDropdown.Item href="project_explorer">Project Explorer</NavDropdown.Item>
                    <NavDropdown.Item href="/project_smallsh">Small Shell</NavDropdown.Item>
                    <NavDropdown.Item href="#porfolio-cicd">Auto Devops With Containerization</NavDropdown.Item>
                    <NavDropdown.Item href="/project_aicrypto">AI Neural Network Crypto Trader</NavDropdown.Item>
                    <NavDropdown.Item href="/project_flaskapp">Flask CRUD App</NavDropdown.Item>
                  </NavDropdown>
                  <Nav.Link href="/about">About Me</Nav.Link>
                  <Nav.Link href="/contact">Contact</Nav.Link>
                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>
        </header>
        <div className="App-body" style={appBodyStyle}>
          <Routes>
            <Route path="/" element={<Home navbarHeight={navbarHeight} />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/project_explorer" element={<ProjectExplorer />} />
            <Route path="/project_smallsh" element={<ProjectSmallsh />} />
            <Route path="/project_aicrypto" element={<ProjectAICrypto />} />
            <Route path="/project_flaskapp" element={<ProjectFlaskApp />} />
          </Routes>
        </div>
      </div>
    </Router>
  );
}


export default App;
