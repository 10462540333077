import React, { useEffect, useState } from 'react';

const Game = () => {
  const [score, setScore] = useState(0);
  const [isFiring, setIsFiring] = useState(false);
  const [lastShotTime, setLastShotTime] = useState(0);
  const FIRE_RATE = 300; // Rate of fire in milliseconds

  useEffect(() => {
    const canvas = document.getElementById('gameCanvas');
    const context = canvas.getContext('2d');
    canvas.width = window.innerWidth;
    canvas.height = window.innerHeight; // Full height without top margin adjustment

    const ship = { x: canvas.width / 2, y: canvas.height - 100, width: 20, height: 20, speed: 5 };

    const BULLET_WIDTH = 5;
    const BULLET_HEIGHT = 10;
    const BULLET_SPEED = 7;

    const bullets = [];
    const enemies = [];

    let touchStartX = 0;

    function drawShip() {
      context.save();
      context.translate(ship.x, ship.y);
      context.beginPath();
      context.moveTo(0, -ship.height / 2);
      context.lineTo(ship.width / 2, ship.height / 2);
      context.lineTo(-ship.width / 2, ship.height / 2);
      context.closePath();
      context.strokeStyle = 'white';
      context.stroke();
      context.restore();
    }

    function drawEnemies() {
      enemies.forEach(enemy => {
        context.save();
        context.translate(enemy.x, enemy.y);
        context.beginPath();
        context.rect(-enemy.width / 2, -enemy.height / 2, enemy.width, enemy.height);
        context.strokeStyle = 'white';
        context.stroke();
        context.restore();
      });
    }

    function drawBullets() {
      bullets.forEach(bullet => {
        context.beginPath();
        context.rect(bullet.x, bullet.y, BULLET_WIDTH, BULLET_HEIGHT);
        context.strokeStyle = 'white';
        context.stroke();
      });
    }

    function drawScore() {
      context.fillStyle = 'white';
      context.font = '24px Arial';
      context.fillText(`Score: ${score}`, 10, 30 ); // Adjust score position with topMargin
    }

    function update() {
      if (keys['ArrowLeft'] && ship.x > ship.width / 2) ship.x -= ship.speed;
      if (keys['ArrowRight'] && ship.x < canvas.width - ship.width / 2) ship.x += ship.speed;

      if (isFiring && Date.now() - lastShotTime > FIRE_RATE) {
        bullets.push({ x: ship.x - BULLET_WIDTH / 2, y: ship.y - ship.height / 2, width: BULLET_WIDTH, height: BULLET_HEIGHT, speed: BULLET_SPEED });
        setLastShotTime(Date.now());
      }

      bullets.forEach((bullet, index) => {
        bullet.y -= bullet.speed;
        if (bullet.y < 0) bullets.splice(index, 1);
      });

      enemies.forEach(enemy => {
        enemy.y += enemy.speed;
      });

      bullets.forEach((bullet, bulletIndex) => {
        enemies.forEach((enemy, enemyIndex) => {
          if (bullet.x < enemy.x + enemy.width / 2 &&
              bullet.x + BULLET_WIDTH > enemy.x - enemy.width / 2 &&
              bullet.y < enemy.y + enemy.height / 2 &&
              bullet.y + BULLET_HEIGHT > enemy.y - enemy.height / 2) {
            bullets.splice(bulletIndex, 1);
            enemies.splice(enemyIndex, 1);
            setScore(prevScore => prevScore + 10); // Increase score
          }
        });
      });
    }

    function draw() {
      context.clearRect(0, 0, canvas.width, canvas.height);
      drawShip();
      drawBullets();
      drawEnemies();
      drawScore(); // Draw the score
    }

    function gameLoop() {
      update();
      draw();
      requestAnimationFrame(gameLoop);
    }

    const keys = {};
    window.addEventListener('keydown', (e) => {
      keys[e.key] = true;
      if (e.key === 'ArrowUp') {
        setIsFiring(true);
      }
    });

    window.addEventListener('keyup', (e) => {
      keys[e.key] = false;
      if (e.key === 'ArrowUp') {
        setIsFiring(false);
      }
    });

    canvas.addEventListener('touchstart', (e) => {
      touchStartX = e.touches[0].clientX;
      setIsFiring(true);
    });

    canvas.addEventListener('touchmove', (e) => {
      const touchX = e.touches[0].clientX;
      if (touchX < touchStartX) {
        ship.x -= ship.speed;
      } else if (touchX > touchStartX) {
        ship.x += ship.speed;
      }
      touchStartX = touchX; // Update touchStartX for the next move event
    });

    canvas.addEventListener('touchend', () => {
      setIsFiring(false);
    });

    for (let i = 0; i < 10; i++) {
      enemies.push({
        x: 50 + i * 60,
        y: 50,
        width: 30,
        height: 30,
        speed: 1
      });
    }

    gameLoop();

    return () => {
      window.removeEventListener('keydown', () => {});
      window.removeEventListener('keyup', () => {});
      canvas.removeEventListener('touchstart', () => {});
      canvas.removeEventListener('touchmove', () => {});
      canvas.removeEventListener('touchend', () => {});
    };
  }, [score, isFiring, lastShotTime]);

  return <canvas id="gameCanvas" style={{ position: 'absolute', top: '0px', left: 0, zIndex: 900, pointerEvents: 'none' }}></canvas>;
};

export default Game;
