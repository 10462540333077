import React, { useState, useEffect } from 'react';
import "./App.css";
import profile from "./derek.jpg";
import { FaLinkedin, FaGitlab, FaRocket } from 'react-icons/fa';
import Game from "./Game";

const Home = ({ navbarHeight }) => {
  const [showGame, setShowGame] = useState(false);

  const handleRocketClick = (e) => {
    e.preventDefault();
    setShowGame(!showGame);
  };

  const style = {
    minHeight: `calc(100vh - ${navbarHeight}px)`,
    overflow: "hidden",
  };

  return (
    <div className="home-container" style={style}>
      <div className="background-overlay"></div>
      <div className="content">
        <img src={profile} alt="Derek Lyon" className="profile-pic" />
        <h1>Hi, I'm Derek - Software Engineer.</h1>
        <p>Welcome to my site!</p>
        <div className="social-links">
          <a href="https://www.linkedin.com/in/derek-lyon" target="_blank" rel="noopener noreferrer">
            <FaLinkedin />
          </a>
          <a href="https://gitlab.lyond-software.net/portfolio" target="_blank" rel="noopener noreferrer">
            <FaGitlab />
          </a>
          <a href="/" onClick={handleRocketClick}>
            <FaRocket />
          </a>
        </div>
      </div>
      {showGame && <Game />}
    </div>
  );
};

export default Home;
